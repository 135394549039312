import { GOAL_SUCCESS, GOAL_FAIL, ADD_GOAL_SUCCESS, ADD_GOAL_FAIL } from './Constants';

const initialState = {
  isLoading: true
};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case GOAL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case GOAL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ADD_GOAL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ADD_GOAL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    default:
      return state;
  }
}