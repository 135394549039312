import { MESSAGE_SUCCESS, MESSAGE_FAIL,SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case MESSAGE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
      case SEND_MESSAGE_SUCCESS:
        return {
          ...state,
          success: action.success,
          fail: '',
          status: action.status,
          type:action.type
        };
      case SEND_MESSAGE_FAIL:
        return {
          ...state,
          success: '',
          fail: action.error,
          status: action.status,
          type:action.type
        };
    default:
      return state;
  }
}