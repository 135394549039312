import React, { Component, createRef } from 'react';
import './Message.scss';
import { Form, Tabs, Tab, FormGroup, Label, Input } from 'react-bootstrap';
// import Select from "react-select";
import * as routes from '../../utils/RouteConstant';
import moment from "moment";
import { connect } from 'react-redux';
import { getMessageRequest, sendMessageRequest } from '../../containers/Messages/MessageContainer/action';
import { MESSAGE_SUCCESS, MESSAGE_FAIL, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAIL } from '../../containers/Messages/MessageContainer/Constants';
import { setClientId } from '../../containers/AppLayoutContainer/action';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import daLocale from "date-fns/locale/da";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import backArrow from '../../images/back-arrow.svg';
import { Cube } from 'react-preloaders2';
class Message extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'isLoading': false,
			'messages': [],
			'paged': 1,
			'messageTypes': [],
			'startDate': '',
			'endDate': '',
			'isMoreRecord': true,
			'clientId': '',
			'message': '',
			'clients': [],
			'isMessageLoading': false,
			'isFormLoading': false,
			'validated': false,
			'messageForm': '',
			'formErrors': ''
		};

		this.journalForm = React.createRef();

		this.getMessages = this.getMessages.bind(this);
		this.getLoadMore = this.getLoadMore.bind(this);
		this.handleFilterMessage = this.handleFilterMessage.bind(this);
		this.handleClearFilterMessage = this.handleClearFilterMessage.bind(this);
		this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
		this.handleSendMessage = this.handleSendMessage.bind(this);
		this.handleSetClientId = this.handleSetClientId.bind(this);
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
		let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
		let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;

		if (clientId > 0) {
			let _this = this;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			setTimeout(() => {
				_this.getMessages();
			}, 500);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { messageSuccess, messageFail, messageStatus, messageType, homeSuccess } = this.props;
		if (homeSuccess && homeSuccess.client_list) {
			if (this.state.clients !== homeSuccess.client_list) {
				this.setState({ clients: homeSuccess.client_list });
				this.setState({ "clientId": webConst.getClientId(), isLoading: false });
			}
		}

		if (messageStatus === 200) {
			if (prevProps.messageSuccess !== messageSuccess) {
				if (messageType == MESSAGE_SUCCESS) {
					this.setState({
						isLoading: false,
						isMessageLoading: false,
						paged: parseInt(this.state.paged) + 1
					});
					if (messageSuccess.messages && messageSuccess.messages.length) {
						this.setState({ messages: [...this.state.messages, ...messageSuccess.messages] });
						this.setState({ isMoreRecord: true });

					}
					else
						this.setState({ isMoreRecord: false });
				}
				else if (messageType === SEND_MESSAGE_SUCCESS) {
					if (this.state.isFormLoading) {
						this.setState({
							isFormLoading: false,
							validated: false,
							message: ""
						});
						let messages = this.state.messages;
						messages.unshift(messageSuccess);
						this.setState({ messages: messages });
						webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="message.success-message" />);
					}
				}
			}
		}

		if (messageStatus === 400) {
			if (this.state.isMessageLoading) {
				this.setState({
					isMessageLoading: false,
					isFormLoading: false,
					isMoreRecord: false
				});
			}
		}
		else if (messageStatus === 501) {
			if (this.state.isFormLoading) {
				this.setState({
					isMessageLoading: false,
					isFormLoading: false
				});
			}
			if (prevProps.messageFail !== messageFail) {
				if (messageFail.message)
					webConst.toaster(webConst.TOAST_ERROR, messageFail.message);
			}
		}
		else if (messageStatus == 422) {
			if (this.state.isFormLoading) {
				this.setState({
					isMessageLoading: false,
					isFormLoading: false
				});
			}
			if (prevProps.messageFail !== messageFail) {
				webConst.toaster(webConst.TOAST_ERROR, <><Translate id="alert-message.form-validition" /></>);
				this.setState({ formErrors: messageFail });
			}
		}
	}

	getLoadMore() {
		if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
			if (this.state.isMoreRecord === true)
				this.getMessages();
			else if (this.state.isLoaded)
				this.setState({ 'isLoaded': false });

		}
	}

	async getMessages() {
		this.setState({ 'isMessageLoading': true });
		const params = new URLSearchParams();
		params.append('user_id', webConst.getUserId());
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append("paged", this.state.paged);
		params.append("start_date", this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : "");
		params.append("end_date", this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : "");
		this.props.getMessageRequest(params);
	}

	handleSetClientId = (e) => {
		let id = e.target.value;
		let _this = this;

		this.setState({
			'messages': [],
			'paged': 1,
			'clientId': id
		});

		setTimeout(() => {
			_this.getMessages();
			_this.props.setClientId(id);
			// webConst.setClientId(id);
		}, 100);
	}

	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleFilterMessage = (event) => {
		event.preventDefault();
		this.setState({
			'messages': [],
			'paged': 1
		});
		let _this = this;
		setTimeout(() => {
			_this.getMessages();
		}, 100);
	}

	handleClearFilterMessage = () => {
		this.setState({
			'messages': [],
			'paged': 1,
			'startDate': '',
			'endDate': ''
		});

		let _this = this;
		setTimeout(() => {
			_this.getMessages();
		}, 100);
	}

	handleSendMessage = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			if (this.state.clientId) {
				const params = new URLSearchParams();
				params.append('user_id', webConst.getUserId());
				params.append('clinic_id', webConst.getClinicId());
				params.append('client_id', webConst.getClientId());
				params.append('message', this.state.message);
				this.props.sendMessageRequest(params);
				this.setState({ "isFormLoading": true });
			} else {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			}
		}
		this.setState({ "validated": true });
	}

	handleClearForm = (event) => {
		this.messageForm.current.reset();
		this.setState({ "message": "", "validated": true });
		this.messageForm.current.classList.remove("was-validated");
	}

	render() {
		var messages = [];
		messages = this.state.messages.map((item, key) => {
			return <div className="col-md-6" key={"message-" + key + "-" + item.id}>
				<div className="validi-user-journal-msg">
					<div className="validi-avatar-details-wrepper">
						<div className="validi-avatar-img">
							{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
						</div>
						<div className="validi-avatar-details">
							<h5 className="validi-subtitle">{this.state.clientName ? this.state.clientName : ""}</h5>
							<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
						</div>
					</div>
					<p className="validi-sub-paragraph" dangerouslySetInnerHTML={{ __html: item.message }}></p>
					<div className="">
						<h4 className="validi-subtitle"><Translate id="message.sender" /> : {webConst.ucfirst(item.user ? item.user.name : "")} {item.sender}</h4>
						<p className="validi-paragraph"><Translate id="message.message-date-time" /> : {item.created_at}</p>
					</div>
				</div>
			</div>
		});

		return (
			<div className="validi-main-layout validi-message-layout">
				<div className="container">
					<div className="validi-back-btn-wrapper">
						<Link to={routes.HOME_ROUTE}>
							<i>
								<img src={backArrow} />
							</i>
							<Translate id="back" />
						</Link>
					</div>
					<div>
						<h1 className="main-title"><Translate id="message.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example">
									<Tab eventKey="view-journal" title={<Translate id="message.view-message" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle"><Translate id="message.view-message" /></h4>
										</div>
										<div className="validi-journal-msg-wrapper">
											<form>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label><Translate id="general.start-date" /></label>
															<DatePicker
																selected={this.state.startDate}
																onChange={(date) => { this.handleChangeDateRange('startDate', date) }}
																selectsStart
																startDate={this.state.startDate}
																endDate={this.state.endDate}
																dateFormat="dd-MM-yyyy"
																className="form-control"
																withPortal
																locale={daLocale}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label><Translate id="general.end-date" /></label>
															<DatePicker
																selected={this.state.endDate}
																onChange={(date) => { this.handleChangeDateRange('endDate', date) }}
																selectsEnd
																startDate={this.state.startDate}
																endDate={this.state.endDate}
																minDate={this.state.startDate}
																dateFormat="dd-MM-yyyy"
																className="form-control"
																withPortal
																locale={daLocale}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<button type="submit" className="validi-theme-btn" onClick={this.handleFilterMessage}><Translate id="general.search" /></button>
														<button type="button" className="validi-theme-btn clear-btn" onClick={this.handleClearFilterMessage}><Translate id="general.clear" /></button>
													</div>
												</div>
											</form>
											<div className='row mt-5'>
												{messages}
											</div>
											<div className="">
												<button className="validi-theme-btn" onClick={this.getLoadMore}>
													{webConst.isLoader(this.state.isMessageLoading, <Translate id="journal.see-more" />)}
												</button>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-journal" title={<Translate id="message.send-message" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle"><Translate id="message.send-message" /></h4>
										</div>
										<div className="row">
											<div className="col-md-6">
												<Form noValidate validated={this.state.validated} onSubmit={this.handleSendMessage} ref={this.messageForm}>
													<Form.Group className="form-group" controlId="journalContent">
														<Form.Label><Translate id="message.message" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="textarea"
																	name="message"
																	value={this.state.message ? this.state.message : ""}
																	onChange={(e) => { this.setState({ message: e.target.value }) }}
																	placeholder={translate("message.enter-message")}
																	as="textarea"
																	rows={3}
																	required
																	// isValid={this.state.formErrors && this.state.formErrors.message ? false : true}
																	// isInvalid={this.state.formErrors && this.state.formErrors.message ? false : true}
																/>
															}
														</Translate>
														{this.state.formErrors && this.state.formErrors.message ? <small className="text-danger">{this.state.formErrors.message}</small> : ""}
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<div className="">
														<button className="validi-theme-btn">
															{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
														</button>
														<button className="validi-theme-btn clear-btn" onClick={this.clearJournalForm}><Translate id="general.clear" /></button>
													</div>
												</Form>
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		messageSuccess: state.message.success,
		messageFail: state.message.fail,
		messageStatus: state.message.status,
		messageType: state.message.type,
		homeSuccess: state.home.success,
		homeStatus: state.home.status,
	};
}

const mapDispatchToProps = { getMessageRequest, setClientId, sendMessageRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Message);
