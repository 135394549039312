import { GOAL_SUCCESS, GOAL_FAIL, ADD_GOAL_SUCCESS,ADD_GOAL_FAIL} from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getGoalListRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.GOALS}?`+ value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(goalSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(goalFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                console.log("error.response.data",error);
                dispatch(goalFailResult(error.response.data.message,error.response.status));
            });
    }
};

export const saveGoalRequest = (value) => {
   return dispatch => {
        axios.post(`${webConst.GOALS}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addGoalSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(addGoalFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                console.log("error",error);
                dispatch(addGoalFailResult(error.response.data.message,error.response.status));
            });
    }
};

export const completeGoalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.COMPLETE_GOAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    response.data.responseBody.responseMessage = response.data.responseMessage;
                    dispatch(goalSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(goalFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(goalFailResult(error.response.data.message,error.response.status));
            });
    }
}

/*
    API RESULT
 */

export const goalSuccessResult = (success, status) => ({
    type: GOAL_SUCCESS,
    success,
    status
});

export const goalFailResult = (error, status) => ({
    type: GOAL_FAIL,
    error,
    status
});

export const addGoalSuccessResult = (success, status) => ({
    type: ADD_GOAL_SUCCESS,
    success,
    status
});

export const addGoalFailResult = (error, status) => ({
    type: ADD_GOAL_FAIL,
    error,
    status
});
