import React from 'react';
import { toast } from 'react-toastify';
import * as routes from '../utils/RouteConstant';
import { Redirect } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Spinner } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

export const WEB_SERVICE_URL = process.env.REACT_APP_WEB_SERVICE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

//auth
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/password/reset';
export const TOKEN_VALIDATE = '/email/verify';
export const REGISTER = '/register';

export const USER = '/users';

//clients
export const CLIENT_API = '/clients';
export const GET_CLIENT_TOOLTIP = '/clients/get-client-tooltip.php';

//goals
export const GOALS = '/goals';
export const CREATE_GOAL = '/goals/create_goal/index.php';
export const COMPLETE_GOAL = '/goals/complete-goal/index.php';
export const GET_GOAL_JOURNAL = '/goals/get-goal-journals/index.php';
export const ADD_GOAL_JOURNAL = '/goals/add-goal-journal/index.php';
export const GET_COMPLETED_GOAL = '/goals/get-completed-goals/index.php';

//journal                       
export const JOURNAL_TYPE = '/journal-types';
export const PERSONAL_JOURNAL = '/journals';

//message
export const MESSAGE = '/messages';

//medicine plan
export const GET_MEDICINE_PLAN_DETAIL = '/medicines/get-medicine-plan-detail';
export const MEDICINE = '/medicines';
export const GET_MEDICINE = '/medicines/get-medicines';
export const GET_MEDICINE_TYPE = '/medicines/get-medicine-type';
export const GET_MEDICINE_STRENGTH = '/medicines/get-medicine-strength';
export const GET_STOCK = '/medicines/get-stock.php';
export const ADD_MEDICINE_STOCK = '/medicines/add-stock.php';
export const GET_MEDICINE_STOCK = '/medicines/get-medicine-stock.php';

// time slot
export const TIME_SLOT = '/time-slots';

//Detox plan
export const DETOX = '/detoxes';
export const GET_DETOX_PLAN = '/medicine-plans/detox-plan/index.php';
export const GET_DETOX_PLAN_DETAIL = '/medicine-plans/detox-plan/detox-detail.php';
export const UPDATE_DETOX_DOSAGE = '/medicine-plans/detox-plan/update-detox-dosage.php';
export const GET_DISPENSE_DETOX_DOSAGE_EVENT = '/detoxes/dosages/get-dispense-events';
export const DETOX_DISPENSE_DOSAGE = '/detoxes/dosages/update-dispense-status';
export const DETOX_DISPENSE_HISTORY = 'detoxes/dosages';
export const DETOX_SUSPENDED_HISTORY = '/detoxes';
export const DETOX_SUSPEND = '/detoxes/detox-medicine-suspend';

//PN detox plan
export const PN_PLAN = '/pns';
export const PN_DOSAGE = '/pns/pn-dosages';
export const PN_SUSPEND = '/pns/pn-medicine-suspend';
export const PN_SUSPENDED_PLAN_HISTORY = '/pns/pn-suspended-history';
export const PN_DISPENSE_HISTORY = '/pns/pn-dispense-history';

//Alcohol plan
export const GET_ALCOHOL_PLAN = '/medicine-plans/alcohol-plan/index.php';
export const GET_ALCOHOL_DISPENSE_DETAIL_PLAN = '/medicine-plans/alcohol-plan/get-alcohol-dispense-detail.php';
export const ALCOHOL_DISPENSE_DOSAGE = '/medicine-plans/alcohol-plan/dispense-alcohol-dosage.php';
export const ALCOHOL_SUSPEND = '/medicine-plans/alcohol-plan/alcohol-suspend.php';
export const ADD_ALCOHOL_PLAN = '/medicine-plans/alcohol-plan/add-alcohol.php';
export const ALCOHOL_SUSPENDED_PLAN_HISTORY = '/medicine-plans/alcohol-plan/alcohol-suspended-history.php';
export const ALCOHOL_DISPENSE_HISTORY = '/medicine-plans/alcohol-plan/alcohol-dispense-history.php';

//add topic
export const TOPIC = '/topics';

// Toater here 
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';

export const LOGIN_USER = 'validi-login';
export const LOGIN_TOKEN = 'validi-app-token';
export const LOGIN_TIME = 'validi-login-time';
export const CLINIC_ID = 'clinic-id';
export const DETOX_ID = 'detox-id';
export const ACTIVE_CLIENT='client';

export const toaster = (toastType, message) => {
    if (toastType === TOAST_SUCCESS) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    } else if (toastType === TOAST_ERROR) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    }
};
// Event Emitter 
var { EventEmitter } = require('fbemitter');
export const emitter = new EventEmitter();

export const LOGIN_EVENT = 'login-event';
// Routes 
export const REDICT_ROIUTE = '/';

export const getApiUrl = () => {
    let url = window.location.origin;
    // if(url==="http://localhost:3000")
    //     return "http://localhost/validi-mini-api/public/api";
    // else if(url==="https://alfaf.validi.eu")
    return "https://api.validi.eu/api";
}

//set token
export const setUserToken = data => {
    let token = data && typeof data.token !== 'undefined' ? data.token : '';
    if (token !== undefined && token !== "") {
        window.localStorage.setItem(LOGIN_TOKEN, encryptString(token.toString()));
        setUser(data);
        return token;
    }
    else
        return "";
};

export const setUser = (data) => {
    let currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);
    window.localStorage.setItem(LOGIN_TIME, currentTime.valueOf());
    window.localStorage.setItem(LOGIN_USER, encryptString(JSON.stringify(data).toString()));
}
// get user
export const getUser = () => {
    var data = window.localStorage.getItem(LOGIN_USER);
    data = data !== '' ? decryptString(data) : "";
    return data && typeof data != undefined ? JSON.parse(data) : '';
};

//check auth
export const checkAuth = () => {
    var data = getUserToken();
    var token = data && typeof data.token !== 'undefined' ? true : false;
    return token;
}

// get user token
export const getUserToken = () => {
    var data = getUser();
    var token = data && typeof data.token !== 'undefined' ? data.token : "";
    return token;
};

// remove token
export const removeUserToken = () => {
    window.localStorage.removeItem(LOGIN_USER);
    window.localStorage.removeItem(LOGIN_TIME);
    window.localStorage.removeItem(ACTIVE_CLIENT);
    emitter.emit(LOGIN_EVENT);
    return <Redirect to='/login' />
};

export const getUserId = () => {
    var data = getUser();
    return data && typeof data !== 'undefined' && typeof data.id !== 'undefined' ? data.id : 0;
};

export const getClinicId = () => {
    var data = getUser();
    return data && typeof data !== 'undefined' && typeof data.clinic_id !== 'undefined' ? data.clinic_id : 0;
}

export const getUserType = () => {
    var data = getUser();
    return data && typeof data !== 'undefined' && typeof data.user_type !== 'undefined' ? data.user_type : "";
}

export const getCurrentUser = () => {
    var data = getUser();
    return data && typeof data !== 'undefined' && typeof data.name !== 'undefined' ? ucfirst(data.name) : "";
}

//get last login time
export const getLoginTime = () => {
    var time = window.localStorage.getItem(LOGIN_TIME);
    return typeof time != undefined ? time : '';
}

// auto logout here 
export const autoLogOut = (props) => {
    props.history.push(routes.LOGIN_ROUTE);
};

// get route 
export const getUserRoute = () => {
    return routes.HOME_ROUTE;
};
// remove route 
export const removeUserRoute = () => {
    return window.localStorage.removeItem(REDICT_ROIUTE);
};

export const loader = (props) => {
    if (props)
        return <img src={BASE_URL + '/images/loading.gif'} alt="wait" />
    else
        return '';
}

export const setClient = (client) => {
    if (client)
        window.localStorage.setItem(ACTIVE_CLIENT, encryptString(JSON.stringify(client).toString()));
}

export const getClientId = () => {
    let data = getClient();
    return data && typeof data !== 'undefined' && typeof data.client_id !== 'undefined' ? data.client_id : 0;
}

export const getClient = () => {
    var client = window.localStorage.getItem(ACTIVE_CLIENT);
    client = client !== '' ? decryptString(client) : "";
    client = client !== '' ? JSON.parse(client) : "";
    return client;
}

export const ucfirst = (str) => {
    var firstLetter = str.substr(0, 1);
    return firstLetter.toUpperCase() + str.substr(1);
}

export const encryptString = (data) => {
    if (data) {
        return CryptoJS.AES.encrypt(data.toString(), 'validi@123456').toString();
    }
    else
        return data;
}

export const decryptString = (data) => {
    if (data) {
        var bytes = CryptoJS.AES.decrypt(data, 'validi@123456');
        data = bytes.toString(CryptoJS.enc.Utf8);
        return data;
    }
    return data;
}

export const isLoader = (load, label) => {
    if (load) {
        return <span>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            <Translate id="general.loading" />
        </span>
    } else {
        return label;
    }
}

export const dataTableConfig = (keyColumn) => {
    return {
        key_column: keyColumn,
        page_size: 10,
        length_menu: [10, 20, 50],
        show_pagination: true,
        show_filter: true,
        pagination: 'advance',
        dynamic: true,
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        language: {
            length_menu: "Show _MENU_",
            filter: "Search....",
            info: "Start _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        }
    }
}

export const getMedicineUrl = (image) => {
    if (image)
        return "https://pro.medicin.dk/resource/media/" + image + "?ptype=1";
    else
        return "";
}

export const setDetoxId = (detoxId) => {
    window.localStorage.setItem(DETOX_ID, detoxId);
}

export const getDetoxId = () => {
    return window.localStorage.getItem(DETOX_ID);
}

export const getUnit = (unit) => {
    return unit.replace(/[^a-zA-Z\/]+/g, '');
}

export const shortType = (type) => {
    if (typeof type !== undefined && typeof type != "undefined")
        return type.substring(0, 4) + ".";
    else
        return type;
}

export const getTranslate = (id) => {
    return <Translate id={id} />
}