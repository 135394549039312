import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, FormGroup, Label, Input, Spinner, Button, Form, Col, Row, InputGroup, Table } from 'react-bootstrap';
import './Goal.scss';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import * as routes from '../../utils/RouteConstant';
import { GOAL_SUCCESS, GOAL_FAIL, ADD_GOAL_SUCCESS, ADD_GOAL_FAIL } from '../../containers/Goals/GoalContainer/Constants';
import { COMPLETED_GOAL_SUCCESS, COMPLETED_GOAL_FAIL } from '../../containers/Goals/CompletedGoalContainer/Constants';
import { getGoalListRequest, saveGoalRequest, completeGoalRequest } from '../../containers/Goals/GoalContainer/action';
import { getCompletedGoalRequest } from '../../containers/Goals/CompletedGoalContainer/action';
import { saveTopicRequest } from '../../containers/Goals/TopicContainer/action';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; //  Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import tickIcon from "../../images/check.png";
import { Link } from 'react-router-dom';
import backArrow from '../../images/back-arrow.svg';
import { Cube } from 'react-preloaders2';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { getPersonalJournalRequest, saveJournalRequest, updateJournalRequest, deleteJournalRequest } from '../../containers/Journals/PersonalJournalContainer/action';
import { PERSONAL_JOURNAL_SUCCESS, ADD_PERSONAL_JOURNAL_SUCCESS, UPDATE_PERSONAL_JOURNAL_SUCCESS, DELETE_PERSONAL_JOURNAL_SUCCESS } from '../../containers/Journals/PersonalJournalContainer/Constants';
import ReactPaginate from 'react-paginate';
import { TOPIC_FAIL, TOPIC_SUCCESS } from '../../containers/Goals/TopicContainer/Constants';

class Goal extends Component {

	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		this.state = {
			'isLogin': props.loginStatus ? props.loginStatus : false,
			'topics': [],
			'goals': [],
			'goalTitle': [],
			'createGoal': false,
			'topicId': 0,
			'clientId': '',
			'goalName': '',
			'activeGoalTabId': '',
			'journal': '',
			'isPartGoalJournal': false,
			'isLoading': false,
			'completedGoals': [],
			'clients': [],
			'clientName': '',
			'clientCPR': '',
			'clientPhoto': '',
			'goal': [],
			'goalValidated': false,
			'journalValidated': {},
			'goalId': '0',
			'partGoalId': '0',
			'isJournalLoading': {},
			'isGoalLoading': false,
			'expandGoal': 'active',
			'expanJournalGoalId': 0,
			'partJournal': '',
			'defaultActiveKey': 0,
			'defaultChildActiveKey': 0,
			'isShowAllAccodian': false,
			'editJournalTime': 0,
			'editedJournalId': 0,
			'isAddTopic': 0,
			'isStaff': 0,
			'isSaveGoal': true,
			'isSaveJournal': true,
			'isSaveTopic': true,
			'isEditPartGoal': false,
			'mainJournal': '',
			'isTopicLoading': false,
			'topicValidated': false,
			'showAllAccodian': [],
			'isCompletedGoal': false,
			'goalJournalId': ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(prevProps) {
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
		let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
		let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
		if (clientId > 0) {
			let _this = this;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			let goalId = 0;
			if (this.props.match && this.props.match.params && this.props.match.params.goalId) {
				goalId = this.props.match.params.goalId;
			}
			if (goalId > 0) {
				const params = new URLSearchParams();
				params.append('goal_id', goalId);
				this.props.getCompletedGoalRequest(params);
			}
			else {
				setTimeout(() => {
					const params = new URLSearchParams();
					params.append('user_id', webConst.getUserId());
					params.append('client_id', webConst.getClientId());
					_this.props.getGoalListRequest(params);
				}, 500);
			}
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		let _this = this;
		if (this.props.match && this.props.match.params && this.props.match.params.goalId) {
			let goalId = this.props.match.params.goalId;
			if (routes.GOAL_ROUTE + "/:goalId?" === this.props.match.path) {
				if (this.state.isCompletedGoal === false)
					this.setState({ isCompletedGoal: true });
			}
		} else {
			if (this.state.isCompletedGoal === true)
				this.setState({ isCompletedGoal: false });
		}

		const { goalSuccess, goalFail, goalStatus, goalType, journalSuccess, journalFail, journalType, journalStatus, topicStatus, topicSuccess, topicFail,topicType, completedGoalSuccess, completedGoalFail, completedGoalStatus } = this.props;
		let goalId = this.state.goalId ? parseInt(this.state.goalId) : "";
		let partGoalId = this.state.partGoalId ? parseInt(this.state.partGoalId) : "";
		if (goalStatus === 200) {
			if (this.state.isLoading) {
				this.setState({
					isLoading: false,
				});
			}

			if (goalType === ADD_GOAL_SUCCESS) {
				if (goalSuccess !== "" && prevProps.goalSuccess !== goalSuccess) {
					if (goalSuccess.message) {
						webConst.toaster(webConst.TOAST_SUCCESS, goalSuccess.message);
					}
					let goal = goalSuccess.goal;
					//append goal
					if (goal.is_parent && goal.is_parent == "1") {
						let goals = this.state.goals.map((goalItem, index) => {
							if (goalItem.id === goalId) {
								goalItem.part_goals.push(goal);
								return goalItem;
							}
							return goalItem;
						});
						this.setState({ goals: goals });
						console.log("goals",goals);						
					}
					else {
						this.setState({ goals: [..._this.state.goals, goal] });
					}

					this.setState({
						'createGoal': false,
						"goalValidated": false,
						"goalName": ""
					});
				}

				setTimeout(function () {
					let journalValidated = {};
					let isJournalLoading = {};
					if (_this.state.goals) {
						_this.state.goals.map((item, key) => {
							if (item.part_goals) {
								item.part_goals.map((partItem, partKey) => {
									if (!journalValidated[partItem.id])
										journalValidated[partItem.id] = false;
									else if (journalValidated[partItem.id] === true)
										journalValidated[partItem.id] = false;

									if (!isJournalLoading[partItem.id])
										isJournalLoading[partItem.id] = false;
									else if (isJournalLoading[partItem.id] === true)
										isJournalLoading[partItem.id] = false;
									return null;
								});
							}
							if (!journalValidated[item.id])
								journalValidated[item.id] = false;
							else if (journalValidated[item.id] === true)
								journalValidated[item.id] = false;

							if (!isJournalLoading[item.id])
								isJournalLoading[item.id] = false;
							else if (isJournalLoading[item.id] === true)
								isJournalLoading[item.id] = false;

							return null;
						});
					}
					if (_this.state.journalValidated != journalValidated)
						_this.setState({ "journalValidated": journalValidated });
					if (_this.state.isJournalLoading != isJournalLoading)
						_this.setState({ "isJournalLoading": isJournalLoading });
				}, 100);
			}

			if (goalType === GOAL_SUCCESS) {
				if (goalSuccess !== "" && prevProps.goalSuccess !== goalSuccess) {
					if (goalSuccess.message) {
						webConst.toaster(webConst.TOAST_SUCCESS, goalSuccess.message);
					}

					this.setState({
						"topics": goalSuccess.topics,
						"goals": goalSuccess.goals,
						"completedGoals": goalSuccess.completed_goals,
						"goalJournalId": goalSuccess.goal_journal_id,
						goalName: ""
					});

					this.setState({ 'createGoal': false });
					let topics = [];
					goalSuccess.topics.map((item, key) => {
						topics.push(<option key={"topic-" + item.id} value={item.id}>{item.name}</option>);
						return null;
					});
					this.setState({ "topics": topics });

				}
			}
		} else {
			if (prevProps.goalFail !== goalFail && this.state.isLoading) {
				this.setState({
					isLoading: false,
				});
			}
		}


		if (journalStatus == 200) {
			if (prevProps.journalSuccess !== journalSuccess) {
				if (journalType == ADD_PERSONAL_JOURNAL_SUCCESS) {
					if (this.props.journalSuccess.message) {
						webConst.toaster(webConst.TOAST_SUCCESS, this.props.journalSuccess.message);
					}
					if (journalSuccess.journals) {
						let journals = journalSuccess.journals;
						//update journal
						if (this.state.isPartGoalJournal === true || this.state.isPartGoalJournal === "true") {
							if (this.state.expandGoal === 'completed') {
								let goals = this.state.completedGoals.map(item => {
									let partGoals = item.part_goals.map(partItem => {
										return partItem.id === partGoalId ? { ...partItem, journals } : partItem
									});
									item.part_goals = partGoals;
									return item;
								});
								this.setState({ completedGoals: goals });
							} else {
								let goals = this.state.goals.map(item => {
									let partGoals = item.part_goals.map(partItem => {
										return partItem.id === partGoalId ? { ...partItem, journals } : partItem
									});
									item.part_goals = partGoals;
									return item;
								});
								this.setState({ goals: goals });
							}
						}
						else {
							if (this.state.expandGoal === 'completed') {
								this.setState({
									completedGoals: this.state.completedGoals.map(el => (el.id === goalId ? { ...el, journals } : el))
								});
							} else {
								this.setState(prevState =>
									({ goals: prevState.goals.map(el => (el.id === goalId ? { ...el, journals } : el)) }));
							}
						}
					}
					setTimeout(() => {
						_this.resetJournal();
					}, 100);
				}
				else if(journalType==PERSONAL_JOURNAL_SUCCESS){
					if (journalSuccess.journals) {
						let journals = journalSuccess.journals;
						//update journal
						console.log("isPartGoalJournal",this.state.isPartGoalJournal);
						console.log("expandGoal",this.state.expandGoal);
						if (this.state.isPartGoalJournal === true || this.state.isPartGoalJournal === "true") {
							if (this.state.expandGoal === 'completed') {
								let goals = this.state.completedGoals.map(item => {
									let partGoals = item.part_goals.map(partItem => {
										return partItem.id === partGoalId ? { ...partItem, journals } : partItem
									});
									item.part_goals = partGoals;
									return item;
								});
								this.setState({ completedGoals: goals });
							} else {
								let goals = this.state.goals.map(item => {
									let partGoals = item.part_goals.map(partItem => {
										return partItem.id === partGoalId ? { ...partItem, journals } : partItem
									});
									item.part_goals = partGoals;
									return item;
								});
								this.setState({ goals: goals });
							}
						}
						else {
							if (this.state.expandGoal === 'completed') {
								this.setState({
									completedGoals: this.state.completedGoals.map(el => (el.id === goalId ? { ...el, journals } : el))
								});
							} else {
								this.setState(prevState =>
									({ goals: prevState.goals.map(el => (el.id === goalId ? { ...el, journals } : el)) }));
							}
						}	
					}
				}
			}
		}
		else {
			if (prevProps.journalFail !== journalFail) {
				this.resetJournal();
			}
		}

		if (topicStatus === 200) {
			console.log(
				"topicType",topicType
			);
			if(topicType==TOPIC_SUCCESS){
				if (this.state.isTopicLoading) {
					this.setState({
						isLoading: false,
						isSaveTopic: true,
						isTopicLoading: false
					});
				}
				if (prevProps.topicSuccess !== topicSuccess) {
					let topics = this.state.topics;
					topics.push(<option key={"topic-" + topicSuccess.topic.id} value={topicSuccess.topic.id}>{topicSuccess.topic.name}</option>);
					this.setState({ 'topics': topics, topicName: "", topicValidated: false });
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="client-goal.topic-save-message" />);
				}
			}
		}
		else {
			if (this.state.isTopicLoading) {
				this.setState({
					isTopicLoading: false,
					isSaveTopic: true,
					topicValidated: false
				});
			}
		}

		if (prevProps.topicFail !== topicFail) {
			if (parseInt(topicStatus) === 402) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="client-goal.topic-exists-message" />);
			}
		}

		if (completedGoalStatus === 200) {
			if (completedGoalSuccess !== "" && prevProps.completedGoalSuccess !== completedGoalSuccess) {
				this.setState({ goals: completedGoalSuccess.client_goals });

			}
		}
		else {
			if (prevProps.goalFail !== goalFail && this.state.isLoading) {
				this.setState({
					isLoading: false,
				});
			}
		}
	}

	// event to handle input change
	handleChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	handleTopicSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ topicValidated: false });
			event.preventDefault();
			event.stopPropagation();
			let message = props => {
				return <Translate id="client-goal.enter-topic-name" />
			}
			webConst.toaster(webConst.TOAST_ERROR, message);
		}
		else {
			if (this.state.isSaveTopic === true) {
				this.setState({ 'isTopicLoading': true });
				const params = new URLSearchParams();
				params.append('name', event.target.topicName.value);
				params.append('user_id', webConst.getUserId());
				this.props.saveTopicRequest(params);
				this.setState({ isTopicJournal: false });
			}
		}
		this.setState({ topicValidated: true });
	}

	handleTopic = () => {
		if (this.state.topicId === 0) {
			let message = props => {
				return <Translate id="client-goal.select-goal-topic-message" />
			}
			webConst.toaster(webConst.TOAST_ERROR, message);
		}
		else
			this.setState({ 'createGoal': true });
	}

	setDefaultActiveKey(defaultActiveKey, defaultChildActiveKey) {
		if (defaultActiveKey !== defaultChildActiveKey) {
			this.setState({ "defaultChildActiveKey": defaultChildActiveKey});
			this.setState({ "defaultActiveKey": defaultActiveKey });
		}
		else
			this.setState({ "defaultActiveKey": defaultActiveKey });
	}

	async handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "goalValidated": false });
			event.preventDefault();
			event.stopPropagation();

			if (this.state.topicId === 0 || this.state.topicId === "") {
				let message = props => {
					return <Translate id="client-goal.select-goal-topic-message" />
				}
				webConst.toaster(webConst.TOAST_ERROR, message);
			}
			else if (event.target.goalName.value === "") {
				let message = props => {
					return <Translate id="client-goal.enter-goal-name-message" />
				}
				webConst.toaster(webConst.TOAST_ERROR, message);
			}

		} else {
			const params = new URLSearchParams();
			params.append('parent_goal_id', event.target.goal_id.value);
			params.append('name', event.target.goalName.value);
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', event.target.client_id.value);
			params.append('topic_id', this.state.topicId);
			params.append('created_by', webConst.getUserId());
			params.append('is_parent', event.target.goal_type.value);
			params.append('part_goal_id', event.target.part_goal_id.value);
			this.props.saveGoalRequest(params);
		}
		this.setState({ "goalValidated": true });
	}

	handleExplandJournal = (goal_id, client_id, defaultActiveKey) => {
		this.setState({ 'expanJournalGoalId': goal_id });
		this.setState({ 'isLoading': true });
		const params = new URLSearchParams();
		params.append('goal_id', goal_id);
		params.append('client_id', client_id);
		params.append('token', webConst.getUserToken());
		this.setDefaultActiveKey(defaultActiveKey, goal_id);
		this.props.getPersonalJournalRequest(params);
	}

	getJournals = (goal_id, page) => {
		this.setState({ 'isLoading': true });
		const params = new URLSearchParams();
		params.append('goal_id', goal_id);
		params.append('client_id', webConst.getClientId());
		params.append('page', parseInt(page)+1);
		this.props.getPersonalJournalRequest(params);
	}

	handleActiveTab = (goalId) => {
		let _this = this;
		var isGoal = 0;
		if (this.state.goals) {
			this.state.goals.map(el => {
				if (el.id === goalId) {
					this.setState({ "activeGoalTabId": el.id, "topicId": el.topic_id, "goalId": el.id, isExpandPartGoal: false, isExpandGoal: true });
					isGoal = 1;
				}
				// if(el.part_goals.length){
				// 	el.part_goals.map(part_el => {
				// 		if (part_el.id === goalId) {
				// 			this.setState({ "topicId": el.topic_id, "goalId": goalId });
				// 			isGoal = 1;
				// 		}
				// 	});
				// }
			});
		}

		if (this.state.completedGoals) {
			this.state.completedGoals.map(el => {
				if (el.id === goalId) {
					this.setState({ "activeGoalTabId": el.id, "topicId": el.topic_id, "goalId": el.id });
					isGoal = 1;
				}
			});
		}
		if (isGoal === 0) {
			this.setState({ "activeGoalTabId": "AddGoal", "topicId": 0, "goalId": 0 });
		}

		if (this.state.showAllAccodian.includes(goalId))
			this.setState({ showAllAccodian: [] });
		else
			this.setState({ showAllAccodian: [goalId] });
		this.setState({ goalId: goalId });
		this.resetJournal();
	}

	handleActiveSubTab = (goalId, partGoalId) => {
		let showAllAccodian = [];
		if (this.state.showAllAccodian.includes(partGoalId)) {
			showAllAccodian.push(goalId);
			showAllAccodian.push(partGoalId);
			this.setState({ showAllAccodian: showAllAccodian, isExpandPartGoal: true });
		}
		else {
			showAllAccodian.push(goalId);
			showAllAccodian.push(partGoalId);
			this.setState({ showAllAccodian: showAllAccodian, isExpandPartGoal: true });
		}
	}

	handleCompleteGoal = (goal_id, clinic_id, client_id) => {
		confirmAlert({
			title: "Fuldfør mål",
			message: "Er du sikker på at gøre dette.",
			buttons: [
				{
					label: "Ja",
					onClick: () => {
						if (goal_id) {
							this.setState({ 'isLoading': true });
							const params = new URLSearchParams();
							params.append('goal_id', goal_id);
							params.append('clinic_id', clinic_id);
							params.append('client_id', client_id);
							params.append('user_id', webConst.getUserId());
							this.props.completeGoalRequest(params);
							this.setState({ "activeGoalTabId": "" });
						}
					}
				},
				{
					label: "Ingen",
					// onClick: () => alert('Click No')
				}
			],
		});
	}

	createGoalJournal(journals,goal_id) {
		if (journals && journals.data && Object.keys(journals.data).length) {
			return <>
				{
					journals.data.map((journalItem, journalKey) => {
						return <div className="validi-journal-msg" key={"journal-" + journalKey}>
							<p className="validi-sub-paragraph" dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></p>
							<div className="validi-journal-user-detail">
								<h4 className="validi-subtitle text-capitalize"><Translate id="journal.added-by" /> : {journalItem.user && journalItem.user.name ? journalItem.user.name : journalItem.name}</h4>
								<p className="validi-paragraph text-capitalize"><Translate id="journal.added-date-time" /> : {journalItem.created_at}</p>
							</div>
						</div>
					})
				}
				
				<ReactPaginate
					nextLabel="next"
					initialPage={"1"}
					onPageChange={(currentPage) => { this.handlePageClick(goal_id, currentPage) }}
					pageRangeDisplayed={1}
					marginPagesDisplayed={2}
					pageCount={journals.last_page}
					previousLabel="previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</>
		} else {
			return <div className="validi-nodata-found">
				<h5><Translate id="alert-message.no-data" /></h5>
			</div>
		}
	}

	createPartGoalForm = () => {
		return <Form noValidate validated={this.state.goalValidated} onSubmit={this.handleSubmit} >
			<input type="hidden" name="goal_id" className="form-control" value={this.state.activeGoalTabId ? this.state.activeGoalTabId : ""} />
			<input type="hidden" name="topic_id" className="form-control" value={this.state.topicId ? this.state.topicId : ""} />
			<input type="hidden" name="client_id" className="form-control" value={this.state.clientId} />
			<input type="hidden" name="goal_type" className="form-control" value="1" />
			<input type="hidden" name="part_goal_id" className="form-control" value="" />
			<Form.Group className="form-group" controlId="add--part-goal">
				<Form.Label><Translate id="client-goal.add-part-goal" /></Form.Label>
				<Translate>
					{({ translate }) =>
						<Form.Control
							type="textarea"
							name="goalName"
							value={this.state.goalName ? this.state.goalName : ""}
							onChange={(e) => { this.setState({ goalName: e.target.value }) }}
							placeholder={translate("client-goal.goal-name")}
							as="textarea"
							rows={3}
							required
						/>
					}
				</Translate>
				<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
			</Form.Group>
			<div className="col-md-6">
				<button type="submit" className="validi-theme-btn">
					{webConst.isLoader(this.state.isGoalLoading, <Translate id="general.save" />)}
				</button>
			</div>
		</Form>
	}

	createJournalForm = (goalId, partGoalId) => {
		let validated = false;
		let loading = false;
		if (partGoalId > 0) {
			validated = this.state.journalValidated[partGoalId] ? true : false;
			loading = this.state.isJournalLoading[partGoalId] ? this.state.isJournalLoading[partGoalId] : false;
		}
		else {
			validated = this.state.journalValidated[goalId] ? true : false;
			loading = this.state.isJournalLoading[goalId] ? this.state.isJournalLoading[goalId] : false;
		}

		return <Form noValidate validated={validated} onSubmit={(event) => { this.handleSubmitjournal(event) }} >
			<Form.Group className="form-group" controlId="add-journal">
				<Form.Label><Translate id="client-goal.add-journal" /></Form.Label>
				{partGoalId > 0
					?
					<Translate>
						{({ translate }) =>
							<Form.Control
								type="textarea"
								name="journal"
								value={this.state.partJournal ? this.state.partJournal : ""}
								onChange={(e) => { this.setState({ partJournal: e.target.value }) }}
								placeholder={translate("client-goal.enter-goal-journal")}
								as="textarea"
								rows={3}
								required
							/>
						}
					</Translate>
					:
					<Translate>
						{({ translate }) =>
							<Form.Control
								type="textarea"
								name="journal"
								value={this.state.journal ? this.state.journal : ""}
								onChange={(e) => { this.handleChange(e) }}
								placeholder={translate("client-goal.enter-goal-journal")}
								as="textarea"
								rows={3}
								required
							/>
						}
					</Translate>
				}
				<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
			</Form.Group>
			<input type="hidden" name="partGoalId" className="form-control" value={partGoalId} />
			<input type="hidden" name="goalId" className="form-control" value={goalId} />
			<div className="col-md-6">
				<button type="submit" className="validi-theme-btn">
					{webConst.isLoader(loading, <Translate id="client-goal.save-journal" />)}
				</button>
			</div>
		</Form>
	}

	async handleSubmitjournal(event) {
		event.preventDefault();
		const form = event.currentTarget;
		let partGoalId = event.target.partGoalId.value;
		let goalId = this.state.activeGoalTabId;
		if (partGoalId > 0) {
			goalId = partGoalId;
			this.setState({ isPartGoalJournal: true, partGoalId: partGoalId });
		}
		else {
			this.setState({ isPartGoalJournal: false, goalId: goalId });
		}

		if (form.checkValidity() === false) {
			this.setState({ journalValidated: { ...this.state.journalValidated, [goalId]: false } });
			event.preventDefault();
			event.stopPropagation();
			let message = props => {
				return <Translate id="client-goal.enter-goal-journal-message" />
			}
			webConst.toaster(webConst.TOAST_ERROR, message);
		}
		else {
			const params = new URLSearchParams();
			if (partGoalId > 0)
				params.append('goal_id', partGoalId);
			else
				params.append('goal_id', goalId);

			params.append('client_id', this.state.clientId);
			params.append('user_id', webConst.getUserId());
			params.append('journal_description', event.target.journal.value);
			params.append('type', 'goal-journal');
			params.append("journal_type_id", this.state.goalJournalId);
			this.props.saveJournalRequest(params);
			event.target.journal.value = "";
			this.setState({ isJournalLoading: { ...this.state.isJournalLoading, [goalId]: false } });
		}
		this.setState({ journalValidated: { ...this.state.journalValidated, [goalId]: true } });
	}

	resetJournal = () => {
		if (this.state.journalValidated) {
			Object.keys(this.state.journalValidated).map(id => {
				if (this.state.journalValidated[id]) {
					this.setState({ journalValidated: { ...this.state.journalValidated, [id]: false } });
				}
				if (this.state.isJournalLoading[id]) {
					this.setState({ isJournalLoading: { ...this.state.isJournalLoading, [id]: false } }, { isPartGoalJournal: false });
				}
			});
		}
		this.setState({ journal: "", partJournal: "", goalValidated: false });
	}

	createGoal = function (item) {
		let goalName = webConst.ucfirst(item.name);
		// start main goal card
		return <Accordion activeKey={this.state.showAllAccodian && this.state.showAllAccodian.includes(item.id) ? item.id : 0} key={"goal-" + item.id}>
			<Accordion.Item eventKey={item.id} className='mb-2'>
				<Accordion.Header className='validi-header-goal' onClick={() => { this.handleActiveTab(item.id); }}>
					{goalName}
				</Accordion.Header>
				<Accordion.Body>
					<div className="validi-tabbar-inner-header">
						<div className="">
							<h4 className="validi-subtitle"><Translate id="client-goal.goal" />  : {goalName} </h4>
							<p className="validi-paragraph"><Translate id="client-goal.topic-name" /> : {webConst.ucfirst(item.topic.name)}</p>
						</div>
						<div className="">
							<button type="button" className="validi-theme-btn" onClick={(e) => { this.handleExplandJournal(item.id, item.client_id, 0, "active") }}><Translate id="client-goal.expand-journal" /></button>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							{this.createJournalForm(item.id, 0)}
							<hr />
							{this.createPartGoalForm()}
						</div>
						<div className="col-md-6">
							<div className="validi-journals-block">
								<h3><Translate id="client-goal.journals" /></h3>
								{this.createGoalJournal(item.journals,item.id)}
							</div>
						</div>
					</div>
					{item.part_goals && Object.values(item.part_goals).length ?
						<div className="validi-part-goals-wrapper" key={"goal-item-" + item.id}>
							<h5 className="validi-user-main-title"><Translate id="client-goal.part-goal" /></h5>
							{
								item.part_goals.map((partItem, index) => {
									return <Accordion className='pb-1' activeKey={this.state.showAllAccodian && this.state.showAllAccodian.includes(partItem.id) ? partItem.id : 0} key={"part-goal-" + partItem.id}>
										<Accordion.Item onClick={() => { this.handleActiveSubTab(item.id, partItem.id); }} eventKey={partItem.id} key={partItem.id}>
											<Accordion.Header className='header-part-goal' onClick={() => { this.setState({ partGoalId: partItem.id }) }}>{webConst.ucfirst(partItem.name)}</Accordion.Header>
											<Accordion.Body>
												<div className="">
													<div className="validi-tabbar-inner-header">
														<div className="">
															<h4 className="validi-subtitle text-capitalize"> <Translate id="client-goal.goal" /> : {partItem.name}</h4>
															<p className="validi-paragraph text-capitalize"> <Translate id="client-goal.topic-name" /> : {item.topic_name}</p>
														</div>
														<div className="d-none">
															<button className="validi-theme-btn" onClick={e => { this.handleExplandJournal(partItem.id, partItem.client_id, partItem.id); }}><Translate id="client-goal.expand-journal" /></button>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															{this.createJournalForm(item.id, partItem.id)}
														</div>
														<div className="col-md-6">
															<div className="validi-journals-block">
																<h3><Translate id="client-goal.journals" /></h3>
																{partItem.journals ? this.createGoalJournal(partItem.journals,partItem.id) : ""}
															</div>
														</div>
													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								})
							}
						</div>
						: ""
					}
					<div className="validi-part-goals-wrapper">
						<div className="validi-complete-goal-footer">
							<button type="button" className="validi-theme-btn" onClick={e => this.handleCompleteGoal(item.id, item.clinic_id, item.client_id)}><Translate id="client-goal.complete-goal" /></button>
						</div>
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	}

	handleShowAllJournal() {
		let isShowAllAccodian = !this.state.isShowAllAccodian;
		let goals = [];
		if (this.state.goals && this.state.goals.length) {
			this.state.goals.map((item, key) => {
				item.part_goals.map((partItem, index) => {
					goals.push(partItem.id);
				});
				goals.push(item.id);
			});
		}
		if (isShowAllAccodian == true)
			this.setState({ isShowAllAccodian: isShowAllAccodian, showAllAccodian: goals });
		else
			this.setState({ isShowAllAccodian: isShowAllAccodian, showAllAccodian: [] });
	}

	createCompletdGoal = (item) => {
		let goalName = webConst.ucfirst(item.name);
		return <Accordion activeKey={this.state.showAllAccodian && this.state.showAllAccodian.includes(item.id) ? item.id : 0} key={"goal-" + item.id}>
			<Accordion.Item eventKey={item.id} className='mb-2'>
				<Accordion.Header className='validi-header-goal' onClick={() => { this.handleActiveTab(item.id) }}>
					{goalName}
				</Accordion.Header>
				<Accordion.Body>
					<div className="validi-tabbar-inner-header">
						<div className="">
							<h4 className="validi-subtitle"><Translate id="client-goal.goal" />  : {goalName} </h4>
							<p className="validi-paragraph"><Translate id="client-goal.topic-name" /> : {webConst.ucfirst(item.topic_name)}</p>
						</div>
						<div className="">
							<button type="button" className="validi-theme-btn" onClick={(e) => { this.handleExplandJournal(item.id, item.client_id, 0) }}><Translate id="client-goal.expand-journal" /></button>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							{this.createJournalForm(item.id, 0)}
							<hr />
							{this.createPartGoalForm()}
						</div>
						<div className="col-md-6">
							<div className="validi-journals-block">
								<h3><Translate id="client-goal.journals" /></h3>
								{this.createGoalJournal(item.journals,item.id)}
							</div>
						</div>
					</div>
					{Object.values(item.part_goals).length ?
						<div className="validi-part-goals-wrapper" key={"goal-item-" + item.id}>
							<h5 className="validi-user-main-title"><Translate id="client-goal.part-goal" /></h5>
							{
								item.part_goals.map((partItem, index) => {
									return <Accordion className='pb-1' activeKey={this.state.showAllAccodian && this.state.showAllAccodian.includes(partItem.id) ? partItem.id : 0} key={"part-goal-" + partItem.id} onClick={() => { this.handleActiveSubTab(item.id, partItem.id) }}>
										<Accordion.Item eventKey={partItem.id} key={partItem.id}>
											<Accordion.Header className='header-part-goal' onClick={() => { this.setState({ partGoalId: partItem.id }) }}>{webConst.ucfirst(partItem.name)}</Accordion.Header>
											<Accordion.Body>
												<div className="">
													<div className="validi-tabbar-inner-header">
														<div className="">
															<h4 className="validi-subtitle text-capitalize"> <Translate id="client-goal.goal" /> : {partItem.name}</h4>
															<p className="validi-paragraph text-capitalize"> <Translate id="client-goal.topic-name" /> : {partItem.topic_name}</p>
														</div>
														<div className="d-none">
															<button className="validi-theme-btn" onClick={e => { this.handleExplandJournal(partItem.id, partItem.client_id, partItem.id); }}><Translate id="client-goal.expand-journal" /></button>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															{this.createJournalForm(item.id, partItem.id)}
														</div>
														<div className="col-md-6">
															<div className="validi-journals-block">
																<h3><Translate id="client-goal.journals" /></h3>
																{this.createGoalJournal(partItem.journals,partItem.id)}
															</div>
														</div>
													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								})
							}
						</div>
						: ""
					}
					<div className="validi-part-goals-wrapper">
						<div className="validi-complete-goal-footer">
							<button type="button" className="validi-theme-btn" onClick={e => this.handleCompleteGoal(item.id, item.clinic_id, item.client_id)}><Translate id="client-goal.complete-goal" /></button>
						</div>
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	}
	handlePageClick(goal_id, page) {
		this.getJournals(goal_id, page.selected);
	}
	render() {
		const { goalSuccess, completedGoalSuccess } = this.props;
		let topicOption = [];
		let clientGoals = [];
		let completedGoals = [];
		let clientName = "";
		// if (goalSuccess) {
		// clientName = goalSuccess.client_name ? goalSuccess.client_name : "";
		if (this.state.topics.length) {
			let topics = this.state.topics;
		}
		// console.log("goals", this.state.goals);
		if (this.state.goals && this.state.goals.length) {
			clientGoals = this.state.goals.map((item, key) => {
				return this.createGoal(item);
			});
		}

		if (this.state.completedGoals && this.state.completedGoals.length) {
			completedGoals = this.state.completedGoals.map((item, key) => {
				return <tr key={key}>
					<td>{key + 1}</td>
					<td className="text-capitalize">{item.topic_name}</td>
					<td className="text-capitalize">
						<Link to={routes.GOAL_DETAIL_ROUTE + '/' + item.id}>{item.name}</Link>
					</td>
					<td>{item.created_at}</td>
				</tr>
			});
		}
		// }

		return (
			<div className="validi-main-layout ">
				<div className="container">
					<div>
						<h1 className="main-title"><Translate id="client-goal.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
							</div>
							{this.state.isCompletedGoal === false ?
								<div className="validi-tabbar-wrapper">
									<Card className="mb-2 border-success" variant={"success"}>
										<Card.Header className={"validi-header-goal"}>
											<div className='row'>
												<div className='col-md-6'>
													<a className="btn text-white"><strong><Translate id="client-goal.title" /></strong></a>
												</div>
												<div className='col-md-6'>
													<div className='d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start'>
														<button type="button" className="btn btn-sm btn-outline-light m-1" onClick={() => { this.handleShowAllJournal() }}>
															{!this.state.isShowAllAccodian ? <Translate id="show-all-journal" /> : <Translate id="hide-all-journal" />}
														</button>
														<button type="button" className="btn btn-sm btn-outline-light m-1" onClick={() => { this.setState({ isAddTopic: 1 }) }}>
															<Translate id="client-goal.add-goal-topic" />
														</button>
														<button type="button" className="btn btn-sm btn-outline-light m-1">
															<Translate id="client-goal.complete-goal" />
														</button>
													</div>
												</div>
											</div>
										</Card.Header>
										<Card.Body>
											{/* START select topic */}
											<div className="row m-0">
												<div className="col-md-6 pb-2 p-0 m-0">
													<Translate>
														{({ translate }) => <select className="form-control" name="topicId" onChange={topicId => this.setState({ "topicId": topicId.target.value })} ><option value="">{translate("client-goal.select-topic")}</option>{this.state.topics}</select>}
													</Translate>
												</div>
												<div className="col-md-6">
													<button type="button" onClick={this.handleTopic} className="validi-theme-btn" disabled={this.state.isLoading}>
														<Translate id="client-goal.create-goal" />
													</button>
												</div>
											</div>
											{/* END select topic */}

											{/* START create goal form */}
											{this.state.createGoal ?
												<div>
													<div className="row m-0 p-0">
														<div className="col-md-8 p-0 pb-2">
															<Form noValidate validated={this.state.goalValidated} onSubmit={this.handleSubmit} >
																<input type="hidden" name="goal_id" className="form-control" value="" />
																<input type="hidden" name="topic_id" className="form-control" value={this.state.topicId ? this.state.topicId : ""} />
																<input type="hidden" name="client_id" className="form-control" value={this.state.clientId} />
																<input type="hidden" name="goal_type" className="form-control" value="0" />
																<input type="hidden" name="part_goal_id" className="form-control" value="" />
																<Form.Group className="form-group" controlId="add--part-goal">
																	<Form.Label><Translate id="client-goal.goal-name" /></Form.Label>
																	<Translate>
																		{({ translate }) =>
																			<Form.Control
																				type="text"
																				name="goalName"
																				value={this.state.goalName ? this.state.goalName : ""}
																				onChange={(e) => { this.setState({ goalName: e.target.value }) }}
																				placeholder={translate("client-goal.goal-name")}
																				required
																			/>
																		}
																	</Translate>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
																<div className="col-md-6">
																	<button type="submit" className="validi-theme-btn">
																		{webConst.isLoader(this.state.isGoalLoading, <Translate id="general.save" />)}
																	</button>
																</div>
															</Form>
														</div>
													</div>
												</div>
												: ""}
											{/* END create goal form */}

											{/* START add topic */}
											{this.state.isAddTopic === 1 ?
												<div>
													<div className="row m-0 p-0">
														<div className="col-md-8 p-0 pb-2">
															<Form noValidate validated={this.state.topicValidated} onSubmit={this.handleTopicSubmit} >
																<Form.Group className="form-group" controlId="add--part-goal">
																	<Form.Label><Translate id="client-goal.topic-name" /></Form.Label>
																	<Translate>
																		{({ translate }) => <input type="text" name="topicName" className="form-control" onChange={e => this.setState({ "topicName": e.target.value })} value={this.state.topicName ? this.state.topicName : ""} placeholder={translate('client-goal.enter-topic-name')} required />}
																	</Translate>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
																<div className="col-md-6">
																	<button type="submit" className="validi-theme-btn">
																		{webConst.isLoader(this.state.isTopicLoading, <Translate id="client-goal.save-topic" />)}
																	</button>
																</div>
															</Form>
														</div>
													</div>
												</div>
												: ""
											}
											{/* END add topic */}
											{clientGoals}
										</Card.Body>
									</Card>
									{/* START completd goal */}
									<div className='border-top border-left border-right mt-5'>
										<h4 className='pt-3'>Completed goals</h4>
										<table className="table table-striped">
											<thead>
												<tr>
													<th>#</th>
													<th>Title</th>
													<th>Goal</th>
													<th>Date time</th>
												</tr>
											</thead>
											<tbody>
												{completedGoals}
											</tbody>
										</table>
									</div>
									{/* END completd goal */}
								</div>
								:
								<div className="validi-tabbar-wrapper">

								</div>
							}
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		);
	}
}


Goal.defaultProps = {
	getGoalListRequest: () => { },
	saveGoalRequest: () => { },
	completeGoalRequest: () => { },
	getGoalJournalRequest: () => { },
	addJournalRequest: () => { },

	goalStatus: '',
	goalSuccess: '',
	goalFail: '',

	journalStatus: '',
	journalSuccess: '',
	journalFail: '',
	clientId: ''
};

Goal.propTypes = {
	getGoalListRequest: PropTypes.func,
	saveGoalRequest: PropTypes.func,
	completeGoalRequest: PropTypes.func,
	getGoalJournalRequest: PropTypes.func,
	addJournalRequest: PropTypes.func,

	goalStatus: PropTypes.any,
	goalSuccess: PropTypes.any,
	goalFail: PropTypes.any,

	journalStatus: PropTypes.any,
	journalSuccess: PropTypes.any,
	journalFail: PropTypes.any
};

function mapStateToProps(state) {
	return {
		goalSuccess: state.goal.success,
		goalFail: state.goal.fail,
		goalStatus: state.goal.status,
		goalType: state.goal.type,
		journalSuccess: state.personalJournal.success,
		journalFail: state.personalJournal.fail,
		journalStatus: state.personalJournal.status,
		journalType: state.personalJournal.type,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		homeSuccess: state.home.success,
		topicSuccess: state.topic.success,
		topicFail: state.topic.fail,
		topicStatus: state.topic.status,
		topicType: state.topic.type,
		completedGoalSuccess: state.completedGoal.success,
		completedGoalFail: state.completedGoal.fail,
		completedGoalStatus: state.completedGoal.status,
		completedGoalType: state.completedGoal.type
	};
}

const mapDispatchToProps = {
	getGoalListRequest,
	saveGoalRequest,
	completeGoalRequest,
	getPersonalJournalRequest,
	saveTopicRequest,
	getCompletedGoalRequest,
	saveJournalRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
